export function formatDate(dateStr) {
  try {
    const dateObj = new Date(dateStr);
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);
    return formattedDate;
  } catch (error) {
    return null;
  }
}
export const adjustedDate = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};
export function truncateText(text, limit) {
  if (text.length <= limit) {
    return text;
  }

  const truncatedText = text.slice(0, limit);
  const lastSpaceIndex = truncatedText.lastIndexOf(" ");

  if (lastSpaceIndex !== -1) {
    return truncatedText.substring(0, lastSpaceIndex) + "...";
  } else {
    return truncatedText + "...";
  }
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function formatCustomDate(timestamp) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dateObj = new Date(timestamp);
  const year = dateObj.getFullYear();
  const month = months[dateObj.getMonth()];
  const day = dateObj.getDate();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const daySuffix = getDaySuffix(day);
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // Check if the formatted time is within the desired range
  const formattedTime = `${formattedHours}:${formattedMinutes}`;
  const isWithinRange = formattedTime >= "00:00" && formattedTime <= "23:59";
  if (!isWithinRange) {
    return "Time is outside the allowed range (00:00 - 23:59)";
  }

  const formattedDate = `${day}${daySuffix} ${month}, ${year} | ${formattedTime}`;
  return formattedDate;
}

export const validateCity = async (cityName) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        cityName
      )}&format=json&limit=3&fuzzy=true`
    );
    const data = await response.json();

    // Check if any results are found
    if (data.length === 0) {
      return false; // Invalid city name
    }

    // Check for exact match or fuzzy match with high confidence score
    const validCity = data.find(
      (city) =>
        city.display_name === cityName ||
        (city.score >= 0.8 &&
          city.display_name.toLowerCase().includes(cityName.toLowerCase()))
    );

    return validCity ? true : false; // Valid city or not
  } catch (error) {
    console.error("Error fetching city data:", error);
    return false; // Handle API errors gracefully
  }
};
