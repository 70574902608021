import React, { useState } from "react";
import styles from "../style.module.css";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../../Firebase_config";
import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Pagination } from "antd";
import { formatCustomDate } from "../../../utils/common";

const Inbox = ({ inboxArray, total, page, setPage, setPageSize, pageSize }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  function sortMessages(messages) {
    const unseenMessages = messages.filter((message) => !message.receiver);
    const seenMessages = messages.filter((message) => message.receiver);

    const sortedUnseen = unseenMessages
      .slice()
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    const sortedSeen = seenMessages
      .slice()
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    return [...sortedUnseen, ...sortedSeen];
  }

  async function updateMessageSeen(outboxId, chatId) {
    const outboxChatUserRef = collection(db, "inbox", outboxId, "chatUser");

    try {
      const querySnapshot = await getDocs(outboxChatUserRef);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.id === chatId) {
          const chatDocRef = doc.ref;
          updateDoc(chatDocRef, {
            receiver: true,
          })
            .then(() => {
              console.log("Message updated to msgSeen: true");
            })
            .catch((error) => {
              console.error("Error updating message:", error);
            });
        }
      });
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  }

  const handlePageChange = (page) => {
    console.log("page", page);
    setCurrentPage(page);
    setPage(page);
  };
  const handlePageSizeChange = (current, size) => {
    console.log("page", current, size);
    setPageSize(size);
  };

  if (inboxArray.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        No data available
      </div>
    );
  }
  console.log("Todal", total);
  return (
    <div className={styles.tableDiv}>
      <TableContainer
        style={{ border: "1px solid #D9E1E7", borderRadius: "15px" }}
      >
        <Table variant="simple">
          {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
          <Thead>
            <Tr>
              <Th style={{ textTransform: "capitalize" }}>Partner Name</Th>
              <Th style={{ textTransform: "capitalize" }}>Subject</Th>
              <Th style={{ textTransform: "capitalize" }}>Message Body</Th>
              <Th style={{ textTransform: "capitalize" }}>Date & Time</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortMessages(inboxArray).map((el, i) => {
              return (
                <Tr
                  key={i}
                  onClick={() => {
                    updateMessageSeen(el.to, el.id).then(() => {
                      navigate(
                        `/Messages/Chat/${el.from}?inbox=true&created_by_type=${el.created_by_type}&chatId=${el.id}`
                      );
                    });
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  className={!el.sender && styles.unseen}
                >
                  <Td>{el.sender_name}</Td>

                  <Td>{el?.subject}</Td>
                  <Td>{el?.Msg}</Td>
                  <Td>{formatCustomDate(el?.timestamp)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {total > 10 && (
        <div className={styles.pagination}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={total}
            onChange={handlePageChange}
            onShowSizeChange={(current, size) =>
              handlePageSizeChange(current, size)
            }
            // showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default Inbox;
