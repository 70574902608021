import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { Button, Input, Select } from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import {  fetchCurrentSubscriptionData, updateCurrentSubscriptionData } from '../../../Redux/Subscription/CurrentSubscriptionReducer'
import { useDispatch } from "react-redux";
import { message } from "antd";
import { InputGroup, InputRightAddon } from "@chakra-ui/react";
import { userRequest } from "../../../requestMethod";
import {
  fetchSubscriptionData,
  updateSubscriptionData,
} from "../../../Redux/Subscription/subscriptionReducer";
// import { userRequest } from '../../../requestMethod'

const initialState = {
  planName: "",
  timePeriod: "",
  subscriptionProductId: "",
  subscriptionPriceId: "",
  price: "",
};

const EditSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const [form, setForm] = useState(initialState);
  console.log("form:", form);
  useEffect(() => {
    userRequest
      .get(`/admin/subscription/getSingleSubscription?_id=${params.id}`)
      .then((res) => {
        setForm(res.data.data);
      });
  }, [params.id]);

  const UpdateData = () => {
    if (!form.planName) {
      message.error("Plan Name is Required");
    } else {
      if (!form.timePeriod) {
        message.error("Time Period is Required");
      } else {
        if (!form.price) {
          message.error("Price is Required");
        } else {
          dispatch(
            updateSubscriptionData({
              ...form,
              planId: form._id,
              userType: "PARTNER",
            })
          )
            .then((res) => {
              navigate("/Subscription");
              fetchSubscriptionData("", "PARTNER");
              message.success("Plan Updated Successfully");
            })
            .catch((err) => {
              message.error("Something went Wrong");
            });
        }
      }
    }
  };

  return (
    <div className={styles.MainContainer}>
      <div className={styles.container1}>
        <p class="font-bold text-lg md:text-base">
          <a
            onClick={() => navigate("/Subscription")}
            class="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            All Plan
          </a>{" "}
          <a class="mx-2">&gt; </a>
          <a>Edit Plan</a>
        </p>

        <h1>Edit Plan</h1>

        <div className={styles.container}>
          <p>Plan Name</p>
          <Select
            placeholder="Choose Plan"
            onChange={(e) => setForm({ ...form, planName: e.target.value })}
            value={form.planName}
          >
            <option value="STARTER">STARTER</option>
            <option value="BUSINESS">BUSINESS </option>
            <option value="ENTERPRISE">ENTERPRISE</option>
          </Select>
          <p>Plan Time</p>
          <InputGroup className={styles.inputGroup}>
            <Input
              placeholder="Enter amount"
              type="Number"
              onChange={(e) => setForm({ ...form, timePeriod: e.target.value })}
              value={form.timePeriod}
            />
            <InputRightAddon children="Month" className={styles.sideItem} />
          </InputGroup>
          <p>Subscription Product Id</p>
          <InputGroup className={styles.inputGroup}>
            <Input
              placeholder="Subscription Product Id"
              type="text"
              onChange={(e) =>
                setForm({ ...form, subscriptionProductId: e.target.value })
              }
              value={form.subscriptionProductId}
            />
            <InputRightAddon children="ID" className={styles.sideItem} />
          </InputGroup>
          <p>Subscription Price Id</p>
          <InputGroup className={styles.inputGroup}>
            <Input
              placeholder="Subscription Price Id"
              type="text"
              onChange={(e) =>
                setForm({ ...form, subscriptionPriceId: e.target.value })
              }
              value={form.subscriptionPriceId}
            />
            <InputRightAddon children="ID" className={styles.sideItem} />
          </InputGroup>
          <p>Price</p>
          <InputGroup className={styles.inputGroup}>
            <Input
              placeholder="Enter amount"
              type="Number"
              onChange={(e) => setForm({ ...form, price: e.target.value })}
              value={form.price}
            />
            <InputRightAddon className={styles.sideItem} children="Є" />
          </InputGroup>
        </div>
      </div>
      <div className={styles.btn}>
        <Button
          colorScheme="black"
          variant="outline"
          onClick={() => navigate("/Subscription")}
        >
          Cancel
        </Button>
        <Button bg="black" color="#fff" variant="solid" onClick={UpdateData}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default EditSubscription;
